import React, { type FunctionComponent, useCallback, Fragment } from 'react';

import type { InputFieldProps } from './Input.types';

const InputField: FunctionComponent<InputFieldProps> = (props) => {
  const {
    value = '',
    onChange,
    onClear,
    placeholder,
    children,
    type = 'text',
  } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  return (
    <Fragment>
      <div className="wm-input-field__wrapper">
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          className="wm-input-field"
          onChange={handleChange}
        />
        {children?.({ value, onChange, onClear })}
      </div>
    </Fragment>
  );
};

export { InputField };
