/* eslint-disable react/no-multi-comp */
import React, { type FunctionComponent, forwardRef, memo } from 'react';

import { Checkbox } from '../ui/checkbox/Checkbox';
import { CheckboxField } from '../ui/checkbox/CheckboxField';
import { CheckboxLabel } from '../ui/checkbox/CheckboxLabel';
import type { RememberMeFieldProps } from './Login.types';

const RememberMe: FunctionComponent<RememberMeFieldProps> = (props) => {
  const { onChange, value } = props;

  return (
    <Checkbox className="wm-login-page__remember-me">
      <CheckboxField checked={value} onChange={onChange} />
      <CheckboxLabel>Tine-ma minte</CheckboxLabel>
    </Checkbox>
  );
};

const RememberMeField: React.FunctionComponent<RememberMeFieldProps> = memo(
  forwardRef((props, ref) => {
    return <RememberMe forwardedRef={ref} {...props} />;
  }),
);

export { RememberMeField };
