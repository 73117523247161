import React, { type FunctionComponent } from 'react';

import type { InputLabelProps } from './Input.types';

const InputLabel: FunctionComponent<InputLabelProps> = (props) => {
  const { children, required = false } = props;

  return (
    <span className="wm-input-field__label">
      {children}
      {required && <span className="wm-input-field__label--required">*</span>}
    </span>
  );
};

export { InputLabel };
