import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CheckboxProps } from './Checkbox.types';

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { children, className } = props;

  const checkboxStyle = classNames('wm-checkbox', className);

  return <div className={checkboxStyle}>{children}</div>;
};

export { Checkbox };
