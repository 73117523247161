import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CollapsibleHeaderProps } from './Collpasible.types';
import { IconDown } from '../../../assets/assets';

const CollapsibleHeader: FunctionComponent<CollapsibleHeaderProps> = (
  props,
) => {
  const { children, selected, className, handleChange } = props;

  const headerStyle = classNames(
    'wm-collapsible__header',
    { 'wm-collapsible__header--selected': selected },
    className,
  );

  return (
    <div className={headerStyle} onClick={handleChange}>
      {children}
      <IconDown
        width={20}
        height={20}
        style={{ transform: selected ? 'rotate(180deg)' : 'unset' }}
      />
    </div>
  );
};

export { CollapsibleHeader };
