import React, { Fragment } from 'react';

import { Outlet, ScrollRestoration, createHashRouter } from 'react-router-dom';
import { ROUTES } from './routers';
import { MapPage } from '../components/map/Map.page';
import { LoginPage } from '../components/login/Login.page';
// import GuardedRoute from '../components/guard/Guard';

const router = createHashRouter([
  {
    path: '/',
    element: (
      <Fragment>
        <Outlet />
        <ScrollRestoration />
      </Fragment>
    ),

    children: [
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      // {
      //   path: ROUTES.ROOT,
      //   element: <GuardedRoute element={<MapPage />} />,
      // },
      {
        path: ROUTES.ROOT,
        element: <MapPage />,
      },
    ],
  },
]);

export { router };
