import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { MapStore } from './map.types';
import type { FilterInterface } from '../../@types/filters';

const STORAGE_VERSION = 7;

const initialState: MapStore = {
  selectedFilters: [],
  token: null,
};

const useMapStore = create<MapStore, [['zustand/persist', MapStore]]>(
  persist(
    (set) => ({
      ...initialState,
      setSelectedFilters: (payload?: FilterInterface[]) =>
        set(() => ({ selectedFilters: payload })),
      setToken: (payload?: string | null) => set(() => ({ token: payload })),
      reset: () =>
        set(() => {
          return { ...initialState };
        }),
    }),
    {
      name: 'water-map-state',
      version: STORAGE_VERSION,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      migrate: (persistedState: MapStore, version: number) => {
        if (version === STORAGE_VERSION - 1) {
          persistedState = initialState;
        }

        return persistedState as MapStore;
      },
    },
  ),
);

export { useMapStore, STORAGE_VERSION };
