import React, { type FunctionComponent } from 'react';

import type { OverlayContainerLayoutProps } from './Layout.types';

const OverlayContainerLayout: FunctionComponent<OverlayContainerLayoutProps> = (
  props,
) => {
  const { children } = props;

  return <div className="wm-overlay-container">{children}</div>;
};

export { OverlayContainerLayout };
