import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CollapsibleTitleProps } from './Collpasible.types';

const CollapsibleTitle: FunctionComponent<CollapsibleTitleProps> = (props) => {
  const { children, className } = props;

  const titleStyle = classNames('wm-collapsible__title', className);

  return <span className={titleStyle}>{children}</span>;
};

export { CollapsibleTitle };
