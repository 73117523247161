import React, { useCallback, type FunctionComponent } from 'react';

import type { CheckboxFieldProps } from './Checkbox.types';

const CheckboxField: FunctionComponent<CheckboxFieldProps> = (props) => {
  const { checked, onChange } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked);
    },
    [onChange],
  );

  return (
    <input
      type="checkbox"
      className="wm-checkbox__field"
      checked={checked}
      onChange={handleChange}
    />
  );
};

export { CheckboxField };
