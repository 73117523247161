import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import { Button } from '../button/Button';
import { type InputButtonProps } from './Input.types';

const InputButton: FunctionComponent<InputButtonProps> = (props) => {
  const { value, onClick, children } = props;

  const buttonStyle = classNames('wm-input-field__icon', {
    'wm-input-field__icon--no-background': !value,
  });

  return (
    <Button onClick={onClick} disabled={!value} className={buttonStyle}>
      {children}
    </Button>
  );
};

export { InputButton };
