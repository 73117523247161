import React, { type FunctionComponent } from 'react';

import { PageContainerLayout } from '../ui/layout/PageContainer.layout';
import GoogleMaps from '../ui/google-maps/GoogleMaps';
import { useLocations } from '../../containers/locations/useLocations';
import { OverlayContainerLayout } from '../ui/layout/OverlayContainer';
import { SectionContainerLayout } from '../ui/layout/SectionContainer.layout';
import { useFilters } from '../../containers/filters/useFilters';
import { Filter } from '../filters/Filter';
import { Filters } from '../filters/Filters';

const MapPage: FunctionComponent = () => {
  const { filteredLocations, shouldHideProperties } = useLocations();

  const {
    propertiesFilters,
    resolutionFilter,
    sourceFilter,
    handleSelectFilter,
  } = useFilters();

  return (
    <PageContainerLayout>
      <GoogleMaps locations={filteredLocations} />
      <OverlayContainerLayout>
        {!!resolutionFilter && (
          <SectionContainerLayout>
            <Filter
              filter={resolutionFilter}
              handleSelectFilter={handleSelectFilter}
            />
          </SectionContainerLayout>
        )}
        {!!sourceFilter && (
          <SectionContainerLayout>
            <Filter
              filter={sourceFilter}
              handleSelectFilter={handleSelectFilter}
            />
          </SectionContainerLayout>
        )}
        {!shouldHideProperties && (
          <SectionContainerLayout>
            <Filters title="Proprietati">
              {propertiesFilters.map((filter) => {
                return (
                  <Filter
                    key={filter.filter_code}
                    filter={filter}
                    handleSelectFilter={handleSelectFilter}
                    showFiltersOnLoad
                  />
                );
              })}
            </Filters>
          </SectionContainerLayout>
        )}
      </OverlayContainerLayout>
    </PageContainerLayout>
  );
};

export { MapPage };
