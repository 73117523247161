import { useCallback, useEffect, useState } from 'react';

import { locations } from '../../data/locations';
import type { LocationInterface } from '../../@types/locations';
import { useMapStore } from '../../store/map/map.store';
import { PROPERTIES_FILTERS } from '../filters/useFilters';

const useLocations = () => {
  const { selectedFilters } = useMapStore((state) => ({
    selectedFilters: state.selectedFilters,
  }));

  const [filteredLocations, setFilteredLocations] =
    useState<LocationInterface[]>(locations);

  /**
   * Get locations with only a specific code
   */
  const filterByCode = useCallback(
    (incomingLocations: LocationInterface[], code: string) => {
      let result: LocationInterface[] = incomingLocations;

      const selectedFilter = selectedFilters.find(
        (filter) => filter.filter_code === code,
      );

      if (selectedFilter) {
        result = [];

        incomingLocations.forEach((location) => {
          const attributeResolution = location.attributes.find(
            (attribute) => attribute.attribute_code === code,
          );

          if (attributeResolution) {
            const isValid = !!selectedFilter.filter_options.find(
              (option) =>
                option.filter_option_code ===
                attributeResolution.attribute_value,
            );

            if (isValid) {
              result.push(location);
            }
          }
        });
      }

      return result;
    },
    [selectedFilters],
  );

  /**
   * Get filters with multiple properties
   */
  const filterByProperties = useCallback(
    (incomingLocations: LocationInterface[]) => {
      let result: LocationInterface[] = incomingLocations;

      const updatedFilters = selectedFilters.filter((item) =>
        PROPERTIES_FILTERS.includes(item.filter_code),
      );

      if (updatedFilters.length) {
        result = [];

        incomingLocations.forEach((location) => {
          let isValid = false;

          location.attributes.forEach((attribute) => {
            const foundFilter = updatedFilters.find(
              (filter) => filter.filter_code === attribute.attribute_code,
            );

            if (foundFilter) {
              const optionIsSelected = !!foundFilter.filter_options.find(
                (option) =>
                  option.filter_option_code === attribute.attribute_value,
              );

              if (optionIsSelected) {
                isValid = true;
              }
            }
          });

          if (isValid) {
            result.push(location);
          }
        });
      }

      return result;
    },
    [selectedFilters],
  );

  const handleApplyFilters = useCallback(() => {
    if (!selectedFilters.length) {
      setFilteredLocations(locations);
      return;
    }

    let updatedLocations: LocationInterface[] = [];

    updatedLocations = filterByCode(locations, 'rezolutie');
    updatedLocations = filterByCode(updatedLocations, 'sursa');
    updatedLocations = filterByProperties(updatedLocations);

    setFilteredLocations(updatedLocations);
  }, [filterByCode, filterByProperties, selectedFilters]);

  useEffect(() => {
    if (selectedFilters) {
      handleApplyFilters();
    }
  }, [handleApplyFilters, selectedFilters]);

  // If potable water is selected, hide properties filters
  const shouldHideProperties = !!selectedFilters
    .find((filter) => filter.filter_code === 'rezolutie')
    ?.filter_options.find((option) => option.filter_option_code === '1');

  return {
    locations,
    filteredLocations,
    shouldHideProperties,
  };
};

export { useLocations };
