import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import type { AdminLoginPayload, UseAdminLoginProps } from './types';
import { AppAPI } from '../../libs/api/api.libs';
import { BrowserPersistence } from '../../utils/persistence';
import base64Helpers from '../../utils/base64';
import { useMapStore } from '../../store/map/map.store';

const BASE_URL =
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  process.env.NODE_ENV === 'development'
    ? ' https://lafantana-uat1.cloudebs.com/index.php/rest/V1'
    : '/index.php/rest/V1';

// The key in local storage where we save the token
const LOCAL_STORAGE_KEY = 'water_map_token';
const persistenceStorage = new BrowserPersistence(sessionStorage);
const storage = new BrowserPersistence(sessionStorage);

const useAdminLogin = (props?: UseAdminLoginProps) => {
  const { onSuccess } = props || {};

  const { setToken } = useMapStore((state) => ({
    setToken: state.setToken,
  }));

  const [provider, setProvider] = useState(null);
  const tfaEnabled = !!provider;

  const saveToken = useCallback(
    (token: string, rememberMe?: boolean) => {
      // if the token has more or less than 3 parts or is not a string
      // then is not a valid token
      if (token.split('.').length !== 3 || typeof token !== 'string') {
        return null;
      }
      // payload ( index 1 ) has the data stored and
      // data about the expiration time
      const payload: string = token.split('.')[1];
      const base64Bytes: number[] = base64Helpers.base64DecToArray(payload);
      // Convert utf-8 array to string
      const jsonPayload: string = decodeURIComponent(
        base64Helpers.UTF8ArrToStr(base64Bytes),
      );
      const decodatePayload = JSON.parse(jsonPayload);
      const ttl = decodatePayload.exp - decodatePayload.iat;

      if (rememberMe) {
        persistenceStorage.setItem(LOCAL_STORAGE_KEY, token, ttl);
      } else {
        storage.setItem(LOCAL_STORAGE_KEY, token, ttl);
      }
      /**
       * Set token in store
       */
      setToken?.(token);
      onSuccess?.();
    },
    [onSuccess, setToken],
  );

  const adminLogin = async (user: AdminLoginPayload) => {
    const { username, password } = user;

    return AppAPI.adminLogin.post<string>('', { username, password });
  };

  const adminLoginTfa = async (user: AdminLoginPayload) => {
    const { username, password, otp } = user;

    const tfaUrl = `${BASE_URL}/tfa/provider/${provider}/authenticate`;

    return await fetch(tfaUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, otp }),
    });
  };

  const { mutate: mutateTfa, error: errorTfa } = useMutation({
    mutationFn: adminLoginTfa,
    onSuccess: async (response, variables) => {
      const token = await response.json();

      saveToken(token, variables.rememberMe);
    },
  });

  const { mutate, error } = useMutation({
    mutationFn: adminLogin,
    onSuccess: (data, variables) => {
      saveToken(data, variables.rememberMe);
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const providers = error.parameters.active_providers;

      if (providers) {
        setProvider(providers[0]);
      }
    },
  });

  const handleLogin = useCallback(
    (user: AdminLoginPayload) => {
      const variables = {
        username: user.username,
        password: user.password,
        otp: user.otp,
        rememberMe: user.rememberMe,
      };

      if (tfaEnabled) {
        mutateTfa(variables);
      } else {
        mutate(variables);
      }
    },
    [mutate, mutateTfa, tfaEnabled],
  );

  return {
    handleLogin,
    tfaEnabled,
    errorMessage: errorTfa?.message || error?.message,
  };
};

export { useAdminLogin };
