import { useCallback, useMemo } from 'react';

import { filters } from '../../data/filters';
import type {
  FilterInterface,
  FilterOptionInterface,
} from '../../@types/filters';
import { useMapStore } from '../../store/map/map.store';

const PROPERTIES_FILTERS = [
  'culoare',
  'miros',
  'e-coli',
  'turbiditate',
  'pH',
  'cond',
  'duritate',
  'nitrati',
  'nitriti',
  'amoniu',
  'cloruri',
  'fier',
  'mangan',
  'coliforme',
  'enterococi',
  'pseudomonas',
];

const useFilters = () => {
  const { selectedFilters, setSelectedFilters } = useMapStore((state) => ({
    selectedFilters: state.selectedFilters,
    setSelectedFilters: state.setSelectedFilters,
  }));

  const toggleOption = (
    options: FilterOptionInterface[],
    option: FilterOptionInterface,
  ) => {
    let updatedOptions: FilterOptionInterface[] = [];

    const isOptionSelected = options.includes(option);

    if (isOptionSelected) {
      // Remove option
      updatedOptions = options.filter(
        (item) => item.filter_option_code !== option.filter_option_code,
      );
    } else {
      // Add another option
      updatedOptions = [...options, option];
    }

    return updatedOptions;
  };

  /**
   * Handle single choice type filter (radio type)
   */
  const handleSingleChoice = useCallback(
    (filter: FilterInterface, option: FilterOptionInterface) => {
      let updatedFilters: FilterInterface[] = [];

      const newFilter = { ...filter, filter_options: [option] };

      // Check if filter code already exists
      const foundFilter = selectedFilters.find(
        (item) => item.filter_code === filter.filter_code,
      );

      if (!foundFilter) {
        updatedFilters = [...selectedFilters, newFilter];
      } else {
        // Delete filter
        updatedFilters = selectedFilters.filter(
          (item) => item.filter_code !== filter.filter_code,
        );

        // Replace option
        if (!foundFilter.filter_options?.includes(option)) {
          updatedFilters = [...updatedFilters, newFilter];
        }
      }

      setSelectedFilters?.(updatedFilters);
    },
    [selectedFilters, setSelectedFilters],
  );

  /**
   * Handle multiple choice type filter (checkbox type)
   */
  const handleMultipleChoice = useCallback(
    (filter: FilterInterface, option: FilterOptionInterface) => {
      let updatedFilters: FilterInterface[] = [];
      let updatedOptions: FilterOptionInterface[] = [];

      // Check if filter code already exists
      const foundFilter = selectedFilters.find(
        (item) => item.filter_code === filter.filter_code,
      );

      if (!foundFilter) {
        updatedFilters = [
          ...selectedFilters,
          { ...filter, filter_options: [option] },
        ];
      } else {
        // Delete filter
        updatedFilters = selectedFilters.filter(
          (item) => item.filter_code !== filter.filter_code,
        );

        if (foundFilter.filter_options) {
          updatedOptions = toggleOption(foundFilter.filter_options, option);

          // If there is one option selected at least, update filters
          if (updatedOptions.length) {
            updatedFilters = [
              ...updatedFilters,
              { ...foundFilter, filter_options: updatedOptions },
            ];
          }
        }
      }

      setSelectedFilters?.(updatedFilters);
    },
    [selectedFilters, setSelectedFilters],
  );

  /**
   * Main function that handles filter select
   */
  const handleSelectFilter = useCallback(
    (filter: FilterInterface, option: FilterOptionInterface) => {
      switch (filter.filter_type) {
        case 'radio':
          handleSingleChoice(filter, option);
          break;
        case 'checkbox':
          handleMultipleChoice(filter, option);
      }
    },
    [handleMultipleChoice, handleSingleChoice],
  );

  const resolutionFilter = useMemo(() => {
    return filters.find((filter) => filter.filter_code === 'rezolutie');
  }, []);

  const sourceFilter = useMemo(() => {
    return filters.find((filter) => filter.filter_code === 'sursa');
  }, []);

  const propertiesFilters = useMemo(() => {
    return filters.filter((filter) =>
      PROPERTIES_FILTERS.includes(filter.filter_code),
    );
  }, []);

  return {
    propertiesFilters,
    resolutionFilter,
    sourceFilter,
    handleSelectFilter,
  };
};

export { useFilters, PROPERTIES_FILTERS };
