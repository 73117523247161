import {
  APIConfigs,
  type ConfigAPIEndpointsEnum,
} from '../../config/api/api.config';
import type { ConfigAPIInterface } from '../../config/api/api.types';
import type {
  PayloadType,
  APIModel,
  FetcherProps,
  ParamsType,
} from './api.types';

/**
 * Convert param object into query string
 * eg.
 *   {foo: 'hi there', bar: { blah: 123, quux: [1, 2, 3] }}
 *   foo=hi there&bar[blah]=123&bar[quux][0]=1&bar[quux][1]=2&bar[quux][2]=3
 */
function serialize(obj: ParamsType, prefix?: string) {
  const str: string[] = [];
  if (typeof obj === 'object') {
    Object.keys(obj).forEach((p) => {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];

      if (v) {
        str.push(
          v !== null && typeof v === 'object'
            ? serialize(v, k)
            : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
        );
      }
    });
  }

  return str.join('&');
}

const fetcherService = <Keys>(config: ConfigAPIInterface<Keys>) => {
  const endpoints = config.endpoints;
  const hostname = config.hostname;
  const errorsMessages = config.errorsMessages;

  const fetcher = async <TResponse>(data: FetcherProps) => {
    const { method, params, body } = data;
    let { endpoint } = data;

    const controller = new AbortController();
    const { signal } = controller;

    // After x seconds, let's call it a day!
    const timeoutAfter = 7;
    const apiTimedOut = setTimeout(
      () => new Error(errorsMessages.timeout),
      timeoutAfter * 3500,
    );

    if (!method || !endpoint) throw Error(errorsMessages.invalidPayload);

    // Build request
    const config: RequestInit = {
      method: method.toUpperCase(),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      signal,
      body: null,
    };

    // Add Endpoint Params
    let urlParams = '';
    if (params) {
      // Object - eg. /products?title=this&cat=2
      if (typeof params === 'object') {
        // Replace matching params in API routes eg. /products/{param}/variations
        Object.keys(params).forEach((param) => {
          if (endpoint.includes(`{${param}}`)) {
            endpoint = endpoint.split(`{${param}}`).join(params[param]);
            delete params[param];
          }
        });

        // Check if there's still an 'id' prop, /{id}?
        if (params.id !== undefined) {
          if (typeof params.id === 'string' || typeof params.id === 'number') {
            urlParams = `/${params.id}`;
            delete params.id;
          }
        }
        if (params.path !== undefined) {
          if (typeof params.path === 'string') {
            urlParams = `/${params.path}`;
            delete params.path;
          }
        }
        // Add the rest of the params as a query string
        urlParams = `?${serialize(params)}`;

        // String or Number - eg. /categories/23
      } else if (typeof params === 'string' || typeof params === 'number') {
        urlParams = `/${params}`;
      }
    }
    // Add Body
    if (body) config.body = JSON.stringify(body);

    const thisUrl = hostname + endpoint + urlParams;

    try {
      // Make the request
      const rawRes = await fetch(thisUrl, config);

      let jsonRes = {};

      if (!rawRes.ok) {
        try {
          jsonRes = await rawRes.json();
        } catch (error) {
          throw new Error(errorsMessages.default);
        }

        throw jsonRes;
      }
      // API got back to us, clear the timeout
      clearTimeout(apiTimedOut);

      try {
        jsonRes = await rawRes.json();
      } catch (error) {
        throw new Error(errorsMessages.invalidJson);
      }
      // Only continue if the header is successful
      if ((rawRes && rawRes.status === 200) || rawRes.status === 201) {
        return jsonRes as TResponse;
      }
      throw jsonRes;
    } catch (error) {
      // API got back to us, clear the timeout
      clearTimeout(apiTimedOut);
      throw error;
    }
  };

  return {
    endpoints,
    fetcher,
  };
};

const getEndpointsWithMethods = <Keys extends string | number | symbol>(
  endpoints: Map<Keys, string>,
  fetcher: <TResponse>(data: FetcherProps) => Promise<TResponse>,
) => {
  const methods = {} as {
    [key in Keys]: APIModel;
  };

  endpoints.forEach((endpoint, key) => {
    methods[key] = {
      get: (params?: ParamsType, payload?: PayloadType) =>
        fetcher({
          method: 'GET',
          endpoint,
          params,
          body: payload,
        }),
      post: (params?: ParamsType, payload?: PayloadType) =>
        fetcher({
          method: 'POST',
          params,
          endpoint,
          body: payload,
        }),
      put: (params?: ParamsType, payload?: PayloadType) =>
        fetcher({
          method: 'PUT',
          endpoint,
          params,
          body: payload,
        }),
      delete: (params?: ParamsType, payload?: PayloadType) =>
        fetcher({
          method: 'DELETE',
          endpoint,
          params,
          body: payload,
        }),
    };
  });

  return methods;
};

/**
 * Build services from Endpoints
 * - So we can call AppAPI.models.get() for example
 */
const { endpoints, fetcher } = fetcherService(APIConfigs);

const AppAPI = getEndpointsWithMethods<ConfigAPIEndpointsEnum>(
  endpoints,
  fetcher,
);

// Export
export { AppAPI };
