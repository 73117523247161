import { QueryClient } from '@tanstack/react-query';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      refetchOnWindowFocus: false,
      retry: 0,
      /**
       * Setting the staleTime to two minutes tells fetchQuery to resolve data immediately
       * if it's available and not older than two minutes, otherwise, it will go and fetch it.
       */
      staleTime: 1000 * 60 * 2,
      /**
       * Setting staleTime to Infinity is almost equivalent to the getQueryData approach,
       * except that manual query invalidation takes precedence over staleTime.
       */
      //   staleTime: Infinity,
    },
  },
});

export { queryClient };
