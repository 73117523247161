import React, { useCallback, type FunctionComponent } from 'react';

import { IconEyeClose, IconEyeOpen } from '../../assets/assets';
import { InputButton } from '../ui/input/InputButton';
import type { PasswordIconProps } from './Login.types';

const PasswordIcon: FunctionComponent<PasswordIconProps> = (props) => {
  const { value, showPassword, togglePassword } = props;

  const onTogglePassword = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.preventDefault();
      togglePassword?.();
    },
    [togglePassword],
  );

  const IconPassword = showPassword ? IconEyeClose : IconEyeOpen;

  return (
    <InputButton value={value} onClick={onTogglePassword}>
      {value.length ? <IconPassword width={22} height={16} /> : undefined}
    </InputButton>
  );
};

export { PasswordIcon };
