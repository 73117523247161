import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { ButtonProps } from './Button.types';

const Button: FunctionComponent<ButtonProps> = (props) => {
  const { children, className, disabled = false, onClick } = props;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };

  const buttonStyles = classNames('wm-button', className, {
    'wm-button--disabled': disabled,
  });

  return (
    <button className={buttonStyles} disabled={disabled} onClick={handleClick}>
      {children}
    </button>
  );
};

export { Button };
