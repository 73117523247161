/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable quotes */
const keyStr =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

const reverseMap = new Map();

for (let i = 0; i < keyStr.length; i++) {
  let bits: string = i.toString(2);
  const padding: number = 6 - bits.length;
  bits = '0'.repeat(padding) + bits;

  reverseMap.set(keyStr.charCodeAt(i), bits);
}

const toByteArray = (base64Str: string): string[] => {
  let bits: string = '';

  // convert base64 string to bits
  for (let i = 0; i < base64Str.length; i++) {
    bits += reverseMap.get(base64Str.charCodeAt(i));
  }

  // Remove padding ("=" characters)
  bits = bits.slice(0, bits.length - (bits.length % 8));

  const bytesArray = [];

  // Separate string by 8-bit groups
  for (let i = 0; i < bits.length / 8; i++) {
    bytesArray.push(bits.slice(i * 8, i * 8 + 8));
  }

  return bytesArray;
};
export default {
  encode(input: string) {
    const output = [];
    let chr1;
    let chr2;
    let chr3 = null;
    let enc1;
    let enc2;
    let enc3;
    let enc4 = null;
    let i = 0;

    do {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output.push(
        keyStr.charAt(enc1) +
          keyStr.charAt(enc2) +
          keyStr.charAt(enc3) +
          keyStr.charAt(enc4),
      );
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return output.join('');
  },

  encodeFromByteArray(input: Array<any>) {
    const output = [];
    let chr1;
    let chr2;
    let chr3 = null;
    let enc1;
    let enc2;
    let enc3;
    let enc4 = null;
    let i = 0;

    do {
      chr1 = input[i++];
      chr2 = input[i++];
      chr3 = input[i++];

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output.push(
        keyStr.charAt(enc1) +
          keyStr.charAt(enc2) +
          keyStr.charAt(enc3) +
          keyStr.charAt(enc4),
      );
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return output.join('');
  },

  decode(input: string) {
    let output = '';
    let chr1;
    let chr2;
    let chr3 = null;
    let enc1;
    let enc2;
    let enc3;
    let enc4 = null;
    let i = 0;

    // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
    const base64test = /[^A-Za-z0-9\+\/\=]/g;
    if (base64test.exec(input)) {
      throw new Error(
        'There were invalid base64 characters in the input text.\n' +
          "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
          'Expect errors in decoding.',
      );
    }
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

    do {
      enc1 = keyStr.indexOf(input.charAt(i++));
      enc2 = keyStr.indexOf(input.charAt(i++));
      enc3 = keyStr.indexOf(input.charAt(i++));
      enc4 = keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = `${output}${String.fromCharCode(chr1)}`;

      if (enc3 != 64) {
        output = `${output}${String.fromCharCode(chr2)}`;
      }
      if (enc4 != 64) {
        output = `${output}${String.fromCharCode(chr3)}`;
      }

      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
    } while (i < input.length);

    return output;
  },
  base64DecToArray: (base64Str: string): number[] => {
    // Replace - _ and remove padding
    base64Str = base64Str.replaceAll('=', '');
    base64Str = base64Str.replaceAll('-', '+');
    base64Str = base64Str.replaceAll('_', '/');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const charCodes: string[] = toByteArray(base64Str);

    return charCodes.map((code) => parseInt(code, 2));
  },
  UTF8ArrToStr(bytes: number[]): string {
    let decoded: string = ''; // Decoded string
    let nPart: number;
    const arrayLength: number = bytes.length;

    for (let i = 0; i < arrayLength; i++) {
      nPart = bytes[i];
      decoded += String.fromCodePoint(
        nPart > 251 && nPart < 254 && i + 5 < arrayLength /* six bytes */
          ? /* (nPart - 252 << 30) may be not so safe in ECMAScript! So... */
            (nPart - 252) * 1073741824 +
              ((bytes[++i] - 128) << 24) +
              ((bytes[++i] - 128) << 18) +
              ((bytes[++i] - 128) << 12) +
              ((bytes[++i] - 128) << 6) +
              bytes[++i] -
              128
          : nPart > 247 && nPart < 252 && i + 4 < arrayLength /* five bytes */
            ? ((nPart - 248) << 24) +
              ((bytes[++i] - 128) << 18) +
              ((bytes[++i] - 128) << 12) +
              ((bytes[++i] - 128) << 6) +
              bytes[++i] -
              128
            : nPart > 239 && nPart < 248 && i + 3 < arrayLength /* four bytes */
              ? ((nPart - 240) << 18) +
                ((bytes[++i] - 128) << 12) +
                ((bytes[++i] - 128) << 6) +
                bytes[++i] -
                128
              : nPart > 223 &&
                  nPart < 240 &&
                  i + 2 < arrayLength /* three bytes */
                ? ((nPart - 224) << 12) +
                  ((bytes[++i] - 128) << 6) +
                  bytes[++i] -
                  128
                : nPart > 191 &&
                    nPart < 224 &&
                    i + 1 < arrayLength /* two bytes */
                  ? ((nPart - 192) << 6) + bytes[++i] - 128 /* nPart < 127 ? */
                  : /* one byte */
                    nPart,
      );
    }

    return decoded;
  },
};
