/* eslint-disable react/no-multi-comp */
import React, {
  type FunctionComponent,
  forwardRef,
  memo,
  useState,
  useCallback,
} from 'react';

import { Input } from '../ui/input/Input';
import type { LoginFieldProps } from './Login.types';
import { InputField } from '../ui/input/InputField';
import { InputLabel } from '../ui/input/InputLabel';
import { PasswordIcon } from './Password.icon';

const Password: FunctionComponent<LoginFieldProps> = (props) => {
  const { onChange, value } = props;

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = useCallback(() => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }, [showPassword]);

  return (
    <Input className="wm-login-page__input">
      <InputLabel required>Parola</InputLabel>
      <InputField
        type={showPassword ? 'text' : 'password'}
        placeholder="Completați"
        value={value}
        onChange={onChange}>
        {({ value }) => {
          return (
            <PasswordIcon
              value={value}
              showPassword={showPassword}
              togglePassword={togglePassword}
            />
          );
        }}
      </InputField>
    </Input>
  );
};

const PasswordField: React.FunctionComponent<LoginFieldProps> = memo(
  forwardRef((props, ref) => {
    return <Password forwardedRef={ref} {...props} />;
  }),
);

export { PasswordField };
