import React, { type ComponentType, type SVGProps } from 'react';
import classNames from 'classnames';

import Location from './icon-location.svg';
import Down from './icon-down.svg';
import EyeOpen from './icon-eye-open.svg';
import EyeClose from './icon-eye-close.svg';

function withClassName(
  WrappedComponent: ComponentType<SVGProps<SVGSVGElement>>,
) {
  const ComponentWithExtraInfo: React.FC<React.SVGProps<SVGSVGElement>> = (
    props,
  ) => {
    const { className, width, height, color, fill, style, ...rest } = props;
    const containerStyle = classNames('wm-icon', className);

    return (
      <WrappedComponent
        className={containerStyle}
        {...rest}
        style={{ width, height, color, fill, ...style }}
      />
    );
  };
  return ComponentWithExtraInfo;
}

const IconLocation = withClassName(Location);
const IconDown = withClassName(Down);
const IconEyeOpen = withClassName(EyeOpen);
const IconEyeClose = withClassName(EyeClose);

export { IconLocation, IconDown, IconEyeOpen, IconEyeClose };
