import React, { type FunctionComponent } from 'react';

import classNames from 'classnames';
import type { InputProps } from './Input.types';

const Input: FunctionComponent<InputProps> = (props) => {
  const { children, className } = props;

  const containerStyle = classNames('wm-input', className);

  return <div className={containerStyle}>{children}</div>;
};

export { Input };
