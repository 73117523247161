import type { FilterInterface } from '../@types/filters';

const filters: FilterInterface[] = [
  {
    filter_code: 'culoare',
    filter_label: 'Culoare',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'miros',
    filter_label: 'Miros',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'e-coli',
    filter_label: 'E-coli',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'turbiditate',
    filter_label: 'Turbiditate',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'pH',
    filter_label: 'pH',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: '7',
      },
      {
        filter_option_code: '1',
        filter_option_label: '6.8',
      },
    ],
  },
  {
    filter_code: 'cond',
    filter_label: 'Cond.',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'duritate',
    filter_label: 'Duritate',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'nitrati',
    filter_label: 'Nitrati',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'nitriti',
    filter_label: 'Nitriti',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'amoniu',
    filter_label: 'Amoniu',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'cloruri',
    filter_label: 'Cloruri',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'fier',
    filter_label: 'Fier',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'mangan',
    filter_label: 'Mangan',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'coliforme',
    filter_label: 'Coliforme',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'enterococi',
    filter_label: 'Enterococi',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'pseudomonas',
    filter_label: 'Pseudomonas',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Nu',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Da',
      },
    ],
  },
  {
    filter_code: 'rezolutie',
    filter_label: 'Rezolutie',
    filter_type: 'radio',
    filter_options: [
      {
        filter_option_code: '1',
        filter_option_label: 'Potabila',
      },
      {
        filter_option_code: '0',
        filter_option_label: 'Nepotabila',
      },
    ],
  },
  {
    filter_code: 'sursa',
    filter_label: 'Sursa',
    filter_type: 'checkbox',
    filter_options: [
      {
        filter_option_code: '0',
        filter_option_label: 'Retea locala',
      },
      {
        filter_option_code: '1',
        filter_option_label: 'Retea',
      },
      {
        filter_option_code: '2',
        filter_option_label: 'Foraj',
      },
    ],
  },
];

export { filters };
