import React, {
  useState,
  type FunctionComponent,
  useCallback,
  useMemo,
} from 'react';

import type { FilterProps } from './Filters.types';
import { Collapsible } from '../ui/collapsible/Collapsible';
import { CollapsibleHeader } from '../ui/collapsible/CollapsibleHeader';
import { CollapsibleTitle } from '../ui/collapsible/CollapsibleTitle';
import { CollapsiblePanel } from '../ui/collapsible/CollapsiblePanel';
import { Checkbox } from '../ui/checkbox/Checkbox';
import { CheckboxField } from '../ui/checkbox/CheckboxField';
import { CheckboxLabel } from '../ui/checkbox/CheckboxLabel';
import { useMapStore } from '../../store/map/map.store';

const Filter: FunctionComponent<FilterProps> = (props) => {
  const { filter, handleSelectFilter, showFiltersOnLoad = false } = props;

  const { selectedFilters } = useMapStore((state) => ({
    selectedFilters: state.selectedFilters,
  }));

  const [showFilters, setShowFilters] = useState(showFiltersOnLoad);

  const toggleCollapsible = useCallback(() => {
    setShowFilters((value) => !value);
  }, []);

  const options = useMemo(() => {
    return filter.filter_options?.map((option) => {
      const checked = !!selectedFilters
        .find((item) => item.filter_code === filter.filter_code)
        ?.filter_options?.find(
          (item) => item.filter_option_code === option.filter_option_code,
        );

      const onChange = () => {
        handleSelectFilter(filter, option);
      };

      return (
        <Checkbox key={option.filter_option_code}>
          <CheckboxField checked={checked} onChange={onChange} />
          <CheckboxLabel checked={checked}>
            {option.filter_option_label}
          </CheckboxLabel>
        </Checkbox>
      );
    });
  }, [filter, handleSelectFilter, selectedFilters]);

  return (
    <Collapsible className="wm-filters-filter">
      <CollapsibleHeader
        className="wm-filters-filter__header"
        selected={showFilters}
        handleChange={toggleCollapsible}>
        <CollapsibleTitle>{filter.filter_label}</CollapsibleTitle>
      </CollapsibleHeader>
      {showFilters && (
        <CollapsiblePanel className="wm-filters-filter__options">
          {options}
        </CollapsiblePanel>
      )}
    </Collapsible>
  );
};

export { Filter };
