import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CheckboxLabelProps } from './Checkbox.types';

const CheckboxLabel: FunctionComponent<CheckboxLabelProps> = (props) => {
  const { children, checked = false } = props;

  const checkboxLabelStyle = classNames('wm-checkbox__label', {
    'wm-checkbox__label--checked': checked,
  });

  return <span className={checkboxLabelStyle}>{children}</span>;
};

export { CheckboxLabel };
