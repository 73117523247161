import type { LocationInterface } from '../@types/locations';

const locations: LocationInterface[] = [
  {
    id: '01',
    name: 'Musat Ionut',
    address: 'Str. Razboieni',
    position: {
      latitude: 43.901285,
      longitude: 25.963474,
    },
    city: 'Giurgiu',
    region: 'Giurgiu',
    ba_number: 1,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '1',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '02',
    name: 'Mugur Ardelean',
    address: 'Str. Razboieni, Nr. 65',
    position: {
      latitude: 43.901285,
      longitude: 25.963474,
    },
    city: 'Giurgiu',
    region: 'Giurgiu',
    ba_number: 2,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '2',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '1',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '03',
    name: 'REGA MED SRL',
    address: 'Strada Agricultorilor, Nr. 1',
    position: {
      latitude: 46.78108,
      longitude: 23.601172,
    },
    city: 'Cluj-Napoca',
    region: 'Cluj',
    ba_number: 3,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '04',
    name: 'Necula Alexandru',
    address: 'Str. Pavlicheni',
    position: {
      latitude: 44.373805,
      longitude: 26.164297,
    },
    city: 'Popesti-Leordeni',
    region: 'Ilfov',
    ba_number: 5,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '05',
    name: 'Bogdan Decusara',
    address: 'Str. Liviu Rebreanu, Nr. 23',
    position: {
      latitude: 44.298869,
      longitude: 26.052806,
    },
    city: 'Comuna 1 Decembrie',
    region: 'Ilfov',
    ba_number: 7,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '06',
    name: 'Iacob Silvia',
    address: 'Str. Garii, Nr. 18',
    position: {
      latitude: 44.464615,
      longitude: 24.233912,
    },
    city: 'Arcesti',
    region: 'Olt',
    ba_number: 9,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '2',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '1',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '1',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '07',
    name: 'INDPRODCOM (Autoliv)',
    address: 'Str. Tapiei',
    position: {
      latitude: 45.686868,
      longitude: 21.892916,
    },
    city: 'Lugoj',
    region: 'Timis',
    ba_number: 11,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '08',
    name: 'DINAMIC OPTIC',
    address: 'Str. Migdalului, Nr. 36',
    position: {
      latitude: 44.465663,
      longitude: 26.030465,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 14,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '09',
    name: 'ANA PAN SA',
    address: 'Bld. Dimitrie Pompeiu, Nr. 5C',
    position: {
      latitude: 44.481076,
      longitude: 26.116774,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 21,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '10',
    name: 'ATU IT SRL',
    address: 'Bld. Basarabia, Nr. 76 A',
    position: {
      latitude: 44.435837,
      longitude: 26.163527,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 19,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '11',
    name: 'Gradinita Paradisul Copiilor',
    address: '-',
    position: {
      latitude: 44.596817,
      longitude: 26.051685,
    },
    city: 'Corbeanca',
    region: 'Ilfov',
    ba_number: 30,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '12',
    name: 'FILIALA EMR FERMUAR SANAYIDE TICARET LIMITED SIRKETI',
    address: '-',
    position: {
      latitude: 44.626293,
      longitude: 26.063647,
    },
    city: 'Saftica',
    region: 'Ilfov',
    ba_number: 32,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '13',
    name: 'Dina Carmen',
    address: '-',
    position: {
      latitude: 44.328681,
      longitude: 23.784863,
    },
    city: 'Craiova',
    region: 'Dolj',
    ba_number: 33,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '14',
    name: 'CORAL CONSTRUCT',
    address: 'Calea Grivitei, Nr. 180',
    position: {
      latitude: 44.457831,
      longitude: 26.060333,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 34,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '15',
    name: 'OTP BANK',
    address: 'Sos. Buzesti',
    position: {
      latitude: 44.449466,
      longitude: 26.083326,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 36,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '16',
    name: 'George Ivan Gabriel',
    address: 'Str. Fericirii, Cosmopolis',
    position: {
      latitude: 44.532691,
      longitude: 26.202069,
    },
    city: 'Stefanestii de jos',
    region: 'Ilfov',
    ba_number: 38,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '17',
    name: 'SUPERBET BETTING & GAMING S.A',
    address: 'Str. Republicii, Nr. 92',
    position: {
      latitude: 44.421911,
      longitude: 22.871576,
    },
    city: 'Vanju Mare',
    region: 'Mehedinti',
    ba_number: 40,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '18',
    name: 'Madalina Matei',
    address: 'Str. Mircea cel Batran, Nr. 55',
    position: {
      latitude: 45.089944,
      longitude: 25.86628,
    },
    city: 'Bordenii Mari',
    region: 'Prahova',
    ba_number: 42,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '19',
    name: 'Maghiar Florin',
    address: '-',
    position: {
      latitude: 47.161449,
      longitude: 22.423556,
    },
    city: 'Padurea Neagra',
    region: 'Bihor',
    ba_number: 44,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '20',
    name: 'Tondinca Bianca',
    address: 'Str. Moldovei, Nr. 50',
    position: {
      latitude: 47.062171,
      longitude: 21.911693,
    },
    city: 'Oradea',
    region: 'Bihor',
    ba_number: 46,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '21',
    name: 'SHOW BY PUTSTEA EMANUEL SRL',
    address: 'Str. Gheorghe Magheru, Nr. 15',
    position: {
      latitude: 46.184702,
      longitude: 21.303096,
    },
    city: 'Arad',
    region: 'Arad',
    ba_number: 47,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '22',
    name: 'Ecaterina Mora',
    address: 'Bld. 1 Mai, Nr. 25',
    position: {
      latitude: 44.199211,
      longitude: 28.634417,
    },
    city: 'Constanta',
    region: 'Constanta',
    ba_number: 49,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '1',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '23',
    name: 'ABC KIDS WORLD SRL',
    address: 'Bld. Theodor Pallady, Nr. 26',
    position: {
      latitude: 44.407193,
      longitude: 26.197066,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 51,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '24',
    name: 'SCHWARTZ ROMANIA',
    address: 'Str. Dimitrie Pompeiu',
    position: {
      latitude: 44.481057,
      longitude: 26.116752,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 53,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '25',
    name: 'BURBANK CALIFORNIA SRL',
    address: 'Bld. Decebal, Nr. 79',
    position: {
      latitude: 46.925047,
      longitude: 26.379139,
    },
    city: 'Piatra Neamt',
    region: 'Neamt',
    ba_number: 55,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '26',
    name: 'Mugur Ardelean',
    address: '-',
    position: {
      latitude: 44.642406,
      longitude: 26.2258617,
    },
    city: 'Moara Vlasiei',
    region: 'Ilfov',
    ba_number: 62,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '2',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '1',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '1',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '1',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '1',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '1',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '1',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '27',
    name: 'NBW INVESTEMENTS GROUP SRL',
    address: 'Str. Opalului, Nr. 23, Casa 2',
    position: {
      latitude: 44.380882,
      longitude: 25.983502,
    },
    city: 'Bragadiru',
    region: 'Ilfov',
    ba_number: 65,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '28',
    name: 'WEST GATE ROMANIA',
    address: 'Aleea Capadiva, Nr. 24-38, Sector 2',
    position: {
      latitude: 44.431864,
      longitude: 25.988242,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 67,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '29',
    name: 'VAT ROMANIA',
    address: 'Zona industriala, Str. 1, Nr. 9',
    position: {
      latitude: 46.197774,
      longitude: 21.286273,
    },
    city: 'Arad',
    region: 'Arad',
    ba_number: 82,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '30',
    name: 'Casa Ardeleneasca SRL',
    address: 'Str. Ardealului, Nr. 1',
    position: {
      latitude: 45.799902,
      longitude: 21.231774,
    },
    city: 'Dumbravita',
    region: 'Timis',
    ba_number: 84,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '31',
    name: 'Suciu Cosmin',
    address: 'Str. Principala, Nr. 102',
    position: {
      latitude: 46.861012,
      longitude: 23.389377,
    },
    city: 'Sardu',
    region: 'Cluj',
    ba_number: 86,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '0',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '0',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '1',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '32',
    name: 'MOULIN D OR',
    address: 'Calea Floreasca, Nr. 246 B',
    position: {
      latitude: 44.466148,
      longitude: 26.102054,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 87,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
  {
    id: '33',
    name: 'ITS DONE ROMANIA',
    address: 'Str. Louis Calderon',
    position: {
      latitude: 44.439884,
      longitude: 26.104865,
    },
    city: 'Bucuresti',
    region: 'Bucuresti',
    ba_number: 89,
    attributes: [
      {
        attribute_code: 'sursa',
        attribute_value: '1',
      },
      {
        attribute_code: 'rezolutie',
        attribute_value: '1',
      },
      {
        attribute_code: 'miros',
        attribute_value: '0',
      },
      {
        attribute_code: 'culoare',
        attribute_value: '0',
      },
      {
        attribute_code: 'turbiditate',
        attribute_value: '0',
      },
      {
        attribute_code: 'pH',
        attribute_value: '0',
      },
      {
        attribute_code: 'cond',
        attribute_value: '0',
      },
      {
        attribute_code: 'duritate',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitrati',
        attribute_value: '0',
      },
      {
        attribute_code: 'nitriti',
        attribute_value: '0',
      },
      {
        attribute_code: 'amoniu',
        attribute_value: '0',
      },
      {
        attribute_code: 'cloruri',
        attribute_value: '0',
      },
      {
        attribute_code: 'fier',
        attribute_value: '0',
      },
      {
        attribute_code: 'mangan',
        attribute_value: '0',
      },
      {
        attribute_code: 'coliforme',
        attribute_value: '0',
      },
      {
        attribute_code: 'e-coli',
        attribute_value: '0',
      },
      {
        attribute_code: 'enterococi',
        attribute_value: '0',
      },
      {
        attribute_code: 'pseudomonas',
        attribute_value: '0',
      },
    ],
  },
];

export { locations };
