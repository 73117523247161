/* eslint-disable react/no-multi-comp */
import React, { type FunctionComponent, forwardRef, memo } from 'react';

import { Input } from '../ui/input/Input';
import type { LoginFieldProps } from './Login.types';
import { InputField } from '../ui/input/InputField';
import { InputLabel } from '../ui/input/InputLabel';

const Username: FunctionComponent<LoginFieldProps> = (props) => {
  const { onChange, value } = props;

  return (
    <Input className="wm-login-page__input">
      <InputLabel required>Utilizator / Email</InputLabel>
      <InputField placeholder="Completați" value={value} onChange={onChange} />
    </Input>
  );
};

const UsernameField: React.FunctionComponent<LoginFieldProps> = memo(
  forwardRef((props, ref) => {
    return <Username forwardedRef={ref} {...props} />;
  }),
);

export { UsernameField };
