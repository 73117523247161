import React, { type FunctionComponent } from 'react';

import type { MarkerInfoProps } from './GoogleMaps.types';
import { useLocation } from '../../../containers/locations/useLocation';

const MarkerInfo: FunctionComponent<MarkerInfoProps> = (props) => {
  const { location } = props;

  const { isPotable, attributes } = useLocation({ location });

  const details = attributes?.reduce(
    (accumulator, currentValue, index) =>
      index === 0
        ? currentValue
        : accumulator.concat(', ').concat(currentValue),
    '',
  );

  return (
    <div className="wm-google-map-marker-info">
      <div className="wm-google-map-marker-info__details">
        <span className="wm-google-map-marker-info__label">
          Buletin de analize nr. :
        </span>
        <span className="wm-google-map-marker-info__value">
          {location.ba_number}
        </span>
      </div>

      <div className="wm-google-map-marker-info__details">
        <span className="wm-google-map-marker-info__label">Rezolutie:</span>
        <span className="wm-google-map-marker-info__value">
          {isPotable ? 'Potabila' : 'Nepotabila'}{' '}
          {!!details && (
            <span className="wm-google-map-marker-info__value--properties">
              {`(${details})`}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export { MarkerInfo };
