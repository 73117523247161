import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CollapsiblePanelProps } from './Collpasible.types';

const CollapsiblePanel: FunctionComponent<CollapsiblePanelProps> = (props) => {
  const { children, className } = props;

  const panelStyle = classNames('wm-collapsible__panel', className);

  return <div className={panelStyle}>{children}</div>;
};

export { CollapsiblePanel };
