import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { CollapsibleProps } from './Collpasible.types';

const Collapsible: FunctionComponent<CollapsibleProps> = (props) => {
  const { children, className } = props;

  const collapsibleStyle = classNames('wm-collapsible', className);

  return <div className={collapsibleStyle}>{children}</div>;
};

export { Collapsible };
