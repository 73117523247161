/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  type FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  memo,
} from 'react';
import { renderToString } from 'react-dom/server';
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import type { GoogleMapsProps } from './GoogleMaps.types';
import { MarkerGreen, MarkerRed } from '../../../assets/assets';
import { MarkerInfo } from './MarkerInfo';

/**
 * Load Google Maps
 */
const loader = new Loader({
  // @ts-ignore
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
});

const GoogleMaps: FunctionComponent<GoogleMapsProps> = (props) => {
  const { locations } = props;

  const map = useRef<google.maps.Map>();
  const markerClusterer = useRef<MarkerClusterer>();
  const selectedInfoWindow = useRef<google.maps.InfoWindow>();
  const mapContainer = useRef<HTMLDivElement | null>(null);

  /**
   * Show marker clusterer on map
   */
  const showMarkers = useCallback(() => {
    if (!map.current) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();

    /**
     * Create markers and fit coordinates
     */
    const markers = locations.map((location) => {
      const isPotable =
        location.attributes.find(
          (attribute) => attribute.attribute_code === 'rezolutie',
        )?.attribute_value === '1';

      const position = location.position;

      const myLatlng = new window.google.maps.LatLng(
        position?.latitude,
        position?.longitude,
      );

      const marker = new window.google.maps.Marker({
        icon: {
          url: isPotable ? MarkerGreen : MarkerRed,
          scaledSize: new window.google.maps.Size(30, 40),
        },
        position: myLatlng,
      });

      const infoWindow = new google.maps.InfoWindow();

      marker.addListener('click', () => {
        if (selectedInfoWindow.current !== null) {
          selectedInfoWindow.current?.close();
        }

        selectedInfoWindow.current = infoWindow;
        selectedInfoWindow.current.setContent(
          renderToString(<MarkerInfo location={location} />),
        );
        selectedInfoWindow.current.open({ map: map.current, anchor: marker });
      });

      bounds.extend(marker.getPosition() as google.maps.LatLng);

      return marker;
    });

    if (markerClusterer.current) {
      markerClusterer.current.clearMarkers();
    }

    markerClusterer.current = new MarkerClusterer({
      markers,
      map: map.current,
    });

    if (!bounds.isEmpty()) {
      map.current.panToBounds(bounds);
    }
  }, [locations]);

  /**
   * Show regions boundaries on map
   */
  const showBoundaries = useCallback(() => {
    if (map.current) {
      const geocoder = new google.maps.Geocoder();

      const featureLayer = map.current.getFeatureLayer(
        google.maps.FeatureType.ADMINISTRATIVE_AREA_LEVEL_1,
      );

      const featureStyleDefault: google.maps.FeatureStyleOptions = {
        strokeColor: '#00469e',
        strokeOpacity: 1,
        strokeWeight: 1.5,
        fillColor: '#ffffff',
        fillOpacity: 0.1,
      };

      const onClick = (e: MouseEvent) => {
        // @ts-ignore
        const placeId = e.features[0].placeId;

        geocoder
          .geocode({ placeId })
          .then(({ results }) => {
            map.current?.setZoom(9);
            map.current?.panTo(results[0].geometry.location);
          })
          .catch(() => {
            throw Error('Geocoder does not work');
          });
      };

      featureLayer.addListener('click', onClick);

      // @ts-ignore
      featureLayer.style = (options: { feature: { placeId: string } }) => {
        const placeId = options.feature.placeId;

        if (
          // @ts-ignore
          process.env.REACT_APP_GOOGLE_MAPS_AVAILABLE_REGIONS.includes(placeId)
        ) {
          return featureStyleDefault;
        }
      };
    }
  }, []);

  /**
   * Show map when ready
   */
  useEffect(() => {
    const loadGoogleMaps = async () => {
      try {
        const { Map } = await loader.importLibrary('maps');

        if (mapContainer.current) {
          map.current = new Map(mapContainer.current, {
            center: new window.google.maps.LatLng(45.904352, 24.837422),
            zoom: 7,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            draggableCursor: 'pointer',
            // @ts-ignore
            mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
          });
        }

        showBoundaries();
        showMarkers();
      } catch {
        throw Error('Could not load the map');
      }
    };

    if (!map.current) {
      loadGoogleMaps();
    } else {
      showMarkers();
    }
  }, [showBoundaries, showMarkers]);

  return <div ref={mapContainer} className="wm-google-map" />;
};

export default memo(GoogleMaps);
