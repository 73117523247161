/**
 * Persistence layer with expiration based on localStorage.
 */

class NamespacedLocalStorage {
  public key;
  private localStorage;
  constructor(localStorage: Storage, key: string) {
    this.localStorage = localStorage;
    this.key = key;
  }

  private _makeKey(key: string): string {
    return `${this.key}__${key}`;
  }

  getItem(name: string) {
    return this.localStorage.getItem(this._makeKey(name));
  }

  setItem(name: string, value: string) {
    return this.localStorage.setItem(this._makeKey(name), value);
  }

  removeItem(name: string) {
    return this.localStorage.removeItem(this._makeKey(name));
  }
}

class BrowserPersistence {
  static KEY = 'WATER_MAP_BROWSER_PERSISTENCE';
  protected storage: NamespacedLocalStorage;

  constructor(localStorage = globalThis.localStorage) {
    this.storage = new NamespacedLocalStorage(
      localStorage,
      BrowserPersistence.KEY,
    );
  }

  getRawItem(name: string) {
    return this.storage.getItem(name);
  }

  getItem(name: string) {
    const now = Date.now();
    const item = this.storage.getItem(name);
    if (!item) {
      return undefined;
    }
    const { value, ttl, timeStored } = JSON.parse(item);

    if (ttl && now - timeStored > ttl * 1000) {
      this.storage.removeItem(name);
      return undefined;
    }

    return JSON.parse(value);
  }

  setItem(
    name: string,
    value: string | number | Record<string, string>,
    ttl?: number,
  ) {
    const timeStored = Date.now();
    this.storage.setItem(
      name,
      JSON.stringify({
        value: JSON.stringify(value),
        timeStored,
        ttl,
      }),
    );
  }

  removeItem(name: string) {
    this.storage.removeItem(name);
  }
}

export { BrowserPersistence };
