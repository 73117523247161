import React, { useCallback, type FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { PageContainerLayout } from '../ui/layout/PageContainer.layout';
import {
  BannerLoginDesktop,
  BannerLoginMobile,
  Logo,
} from '../../assets/assets';
import { ROUTES } from '../../routers/routers';
import type { LoginFormValuesInterface } from './Login.types';
import { UsernameField } from './Username.field';
import { PasswordField } from './Password.field';
import { Button } from '../ui/button/Button';
import { ButtonTitle } from '../ui/button/ButtonTitle';
import { useAdminLogin } from '../../containers/login/useAdminLogin';
import { OtpField } from './Otp.field';
import { RememberMeField } from './RememberMe.field';
import { InputErrorMessage } from '../ui/input/InputErrorMessage';

const LoginPage: FunctionComponent = () => {
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    navigate(ROUTES.ROOT);
  }, [navigate]);

  const { handleLogin, tfaEnabled, errorMessage } = useAdminLogin({
    onSuccess,
  });

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      username: '',
      password: '',
      otp: '',
      rememberMe: false,
    },
  });

  const { isValid } = formState;

  const onSubmit = (data: LoginFormValuesInterface) => {
    handleLogin(data);
  };

  return (
    <PageContainerLayout>
      <div className="wm-login-page__container">
        <img src={Logo} className="wm-login-page__logo" />
        <span className="wm-login-page__text">
          <span className="wm-login-page__text--big">Salut!</span>
          <br />
          introdu datele contului tau.
        </span>
        <div className="wm-login-page__form">
          <form>
            <Controller
              name="username"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UsernameField {...field} control={control} />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PasswordField {...field} control={control} />
              )}
            />
            {tfaEnabled && (
              <Controller
                name="otp"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <OtpField {...field} control={control} />
                )}
              />
            )}
            {!!errorMessage && (
              <InputErrorMessage>{errorMessage}</InputErrorMessage>
            )}
            <Button
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
              className="wm-login-page__action">
              <ButtonTitle>Pasul urmator</ButtonTitle>
            </Button>

            <div className="wm-login-page__options">
              <Controller
                name="rememberMe"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <RememberMeField {...field} control={control} />
                )}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="wm-login-page__img-container">
        <picture>
          <source media="(min-width: 768px)" srcSet={BannerLoginDesktop} />
          <img
            src={BannerLoginMobile}
            className="wm-login-page__image"
            alt=""
          />
        </picture>
      </div>
    </PageContainerLayout>
  );
};

export { LoginPage };
