import React, { type FunctionComponent } from 'react';

import type { InputErrorMessageProps } from './Input.types';

const InputErrorMessage: FunctionComponent<InputErrorMessageProps> = (
  props,
) => {
  const { children } = props;

  return <span className="wm-input__error-message">{children}</span>;
};

export { InputErrorMessage };
