import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { PageContainerLayoutProps } from './Layout.types';

const PageContainerLayout: FunctionComponent<PageContainerLayoutProps> = (
  props,
) => {
  const { children, className } = props;

  const containerStyles = classNames('wm-page-container', className);

  return <div className={containerStyles}>{children}</div>;
};

export { PageContainerLayout };
