import { useMemo } from 'react';
import type { UseLocationProps } from './types';

import { PROPERTIES_FILTERS } from '../filters/useFilters';

const useLocation = (props: UseLocationProps) => {
  const { location } = props;

  const isPotable =
    location.attributes.find(
      (attribute) => attribute.attribute_code === 'rezolutie',
    )?.attribute_value === '1';

  const attributes = useMemo(() => {
    return !isPotable
      ? location.attributes
          .filter(
            (attribute) =>
              attribute.attribute_value === '1' &&
              PROPERTIES_FILTERS.includes(attribute.attribute_code),
          )
          .map((item) => item.attribute_code)
      : undefined;
  }, [isPotable, location.attributes]);

  return {
    isPotable,
    attributes,
  };
};

export { useLocation };
