import React, { type FunctionComponent } from 'react';
import classNames from 'classnames';

import type { ButtonTitleProps } from './Button.types';

const ButtonTitle: FunctionComponent<ButtonTitleProps> = (props) => {
  const { children, className } = props;

  const buttonStyles = classNames('wm-button-title', className);

  return <span className={buttonStyles}>{children}</span>;
};

export { ButtonTitle };
