import React, {
  useState,
  type FunctionComponent,
  useCallback,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

import { Collapsible } from '../ui/collapsible/Collapsible';
import { CollapsibleHeader } from '../ui/collapsible/CollapsibleHeader';
import { CollapsibleTitle } from '../ui/collapsible/CollapsibleTitle';
import { CollapsiblePanel } from '../ui/collapsible/CollapsiblePanel';
import type { FiltersProps } from './Filters.types';

const Filters: FunctionComponent<FiltersProps> = (props) => {
  const { children, title } = props;

  const [showFilters, setShowFilters] = useState(false);

  const toggleCollapsible = useCallback(() => {
    setShowFilters((value) => !value);
  }, []);

  return (
    <Collapsible>
      <CollapsibleHeader
        selected={showFilters}
        handleChange={toggleCollapsible}>
        <CollapsibleTitle>{title || 'Filtreaza'}</CollapsibleTitle>
      </CollapsibleHeader>
      {showFilters && (
        <CollapsiblePanel>
          <div className="wm-filters__container">
            {Children.map(children, (child) => {
              if (isValidElement(child)) {
                return cloneElement(child);
              }
            })}
          </div>
        </CollapsiblePanel>
      )}
    </Collapsible>
  );
};

export { Filters };
