import React, { type FunctionComponent } from 'react';
import type { SectionContainerLayoutProps } from './Layout.types';

const SectionContainerLayout: FunctionComponent<SectionContainerLayoutProps> = (
  props,
) => {
  const { children } = props;

  return <section className="wm-section-container">{children}</section>;
};

export { SectionContainerLayout };
