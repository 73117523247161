import React, { StrictMode, type FunctionComponent } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { RouterProvider } from 'react-router-dom';

import { router } from './routers/root';
import { queryClient } from './libs/queryClient';

const App: FunctionComponent = () => {
  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </StrictMode>
  );
};

export default App;
