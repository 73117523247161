/**
 * API Config
 */

enum ConfigAPIEndpointsEnum {
  ADMIN_LOGIN = 'adminLogin',
}

const APIConfigs = {
  // The URL we're connecting to
  hostname:
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    process.env.NODE_ENV === 'development'
      ? 'https://lafantana-uat1.cloudebs.com/index.php/rest/V1'
      : 'https://lafantana.ro/index.php/rest/V1',

  // Map shortnames to the actual endpoints, so that we can
  // use them like so: AppAPI.ENDPOINT_NAME.METHOD()
  //  NOTE: They should start with a /
  //    eg.
  //    - AppAPI.models.get()

  endpoints: new Map<ConfigAPIEndpointsEnum, string>([
    [ConfigAPIEndpointsEnum.ADMIN_LOGIN, '/integration/admin/token'],
  ]),

  // Which 'endpoint' key deals with our tokens?
  tokenKey: '',

  errorsMessages: {
    timeout:
      'Aplicația nu a primit răspuns de la server. Verificați conexiunea la internet.',
    invalidJson: 'Răspunsul primit de la server nu are formatul dorit.',
    invalidPayload: 'Parametrii pentru datele de afișare lipsesc.',

    // Errors
    missingResult: 'Nu există date de afișat.',
    default: 'A apărut o eroare necunoscută.',
  },
};

export { APIConfigs, ConfigAPIEndpointsEnum };
